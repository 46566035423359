<script>
import axios from "axios";

import Layout from "../../layouts/auth";
import {
  authMethods,
  authFackMethods,
  notificationMethods,
} from "@/state/helpers";
import { mapState } from "vuex";

import appConfig from "@/app.config";
import { required, username, helpers, sameAs } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

import { useQuasar } from 'quasar'
import { Notify } from 'quasar'
import { SimpleBar } from "simplebar-vue3"

import Swal from 'sweetalert2'




/**
 * Login component
 */
export default {
  setup() {
    const $q = useQuasar()
    return { v$: useVuelidate() };
  },
  page: {
    title: "Login",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    SimpleBar
  },
  data() {
    return {

      user: {

        username: "",
        email: "",
        password: "",

        profile: {
          firstName: "",
          lastName: ""


        },

        cellphoneNumber: "",
        acc_type: "player",
        referred_by: ''
      },


      errorMessage: "",
      submitted: false,
      regError: null,
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false,
    };
  },
  validations: {
    user: {
      username: {
        required: helpers.withMessage("Username is required", required),
      },
      email: {
        required: helpers.withMessage("Email is required", required),

      },
      profile: {
        firstName: {
          required: helpers.withMessage("First name is required", required),
        },
        lastName: {
          required: helpers.withMessage("Last name is required", required),
        },

      },
      cellphoneNumber: {
        required: helpers.withMessage("Cellphone Number is required", required),
      },
      password: {
        required: helpers.withMessage("Password is required", required),
      },
      confirmPassword: {
        required: helpers.withMessage("Confirm password", required),
        // sameAsPassword: sameAs("password"),
      },

    },

  },
  computed: {
    ...mapState("authfack", ["status"]),
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },

  mounted() {
    document.body.classList.add("auth-body-bg");

  },
  methods: {
    ...authMethods,
    ...authFackMethods,
    ...notificationMethods,
    // Try to log the user in with the username
    // and password they provided.
    tryToRegisterIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      } else {


        delete axios.defaults.headers.common["Authorization"];

        try {
          this.referred_by = this.$route.params.referral
          if (this.referred_by == undefined)
          this.referred_by = 'admin'
        }
        catch {
          
         this.referred_by = 'admin'
        }



        axios
          .post("/api/users/", {
            username: this.user.username,
            email: this.user.email,
            password: this.user.password,
            cellphoneNumber: this.user.cellphoneNumber,
            profile: this.user.profile,
            acc_type: this.user.acc_type,
            referred_by: this.referred_by


          })
          .then((res) => {


            this.$router.push('/login');
          })
          .catch((error) => {
            console.log(error.response.data)
            let errorMessages = "";
              for (let key in error.response.data) {
                errorMessages += key + ": " + error.response.data[key] + "\n";
              }
     


            



            const Toast = Swal.mixin({
  toast: true,
  background: "#2A3042",
  color: "white",
  position: 'top-end',
  showConfirmButton: false,
  timer: 10000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

Toast.fire({
  icon: 'error',
  title: `${errorMessages}`
})
          });
        ;
      }
    },
  },

};
</script>
<style>
.full-screen {
  height: 100%;
  position: absolute;
}

#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}
</style>
<template>
  <div>
    <div class="container-fluid p-0">
      <div class="row g-0">
        <div class="col-xl-9">
          <div class=" auth-full-bg pt-lg-5 p-4">
            <div class="w-100">
              <div id="myVideo" class= "bg-overlay"></div>
              <!-- <div class="d-flex h-100 flex-column">
                
                <div class="p-4 mt-auto">
                  
                  <div class="row justify-content-center">
                    
  
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <!-- end col -->

        <div class="col-xl-3">
          <div class="auth-full-page-content p-md-4 p-5">
            <div class="w-100">
              <div class="d-flex flex-column h-100">

                <div class="my-auto">
                  <div class="row justify-content-center">
                    <div class="col-xl-5 col-xl-5 col-xl-11">
                      <div class="card overflow-hidden">
                        <div class="bg-soft bg-primary">
                          <div class="row">
                            <div class="col-9">
                              
                              <h5 class="text-white  mx-3 my-3">Registration</h5><br>

                              
                            </div>
                            <div class="col-3 my-3 align-self-end">
                              <img src="@/assets/images/logos/DO.png" width="80" height="80" alt class="img-fluid" />
                            </div>
                          </div>
                        </div>
                        <div class="card-body pt-0">
                          <div>
                            <router-link to="/">
                              <div class="avatar-md profile-user-wid mb-0">
                                <span class="avatar-title rounded-circle bg-transparent">	
                                  <img src="@/assets/images/logos/sitelogo.png" alt class="img-fluid" height="80" width="80"  />
                                </span>
                              </div>
                            </router-link>
                          </div>
                       
                          <SimpleBar style="max-height: 400px">
                            <b-form class="p-2" @submit.prevent="tryToRegisterIn">
                              <b-form-group class="mb-3" id="username" label="Username" label-for="username">
                                <b-form-input id="username" v-model="user.username" type="text"
                                  placeholder="Enter username" :class="{
  'is-invalid': submitted && v$.user.username.$error,
}"></b-form-input>
                                <div v-if="submitted && v$.user.username.$error" class="invalid-feedback">
                                  <span v-if="v$.user.username.required.$message">{{
    v$.user.username.required.$message
}}</span>
                                </div>
                              </b-form-group>
                              <b-form-group class="mb-3" id="email-group" label="First Name" label-for="firstName">
                                <b-form-input id="firstName" v-model="user.profile.firstName" type="text"
                                  placeholder="Enter first name" :class="{
  'is-invalid': submitted && v$.user.profile.firstName.$error,
}"></b-form-input>
                                <div v-if="submitted && v$.user.profile.firstName.$error" class="invalid-feedback">
                                  <span v-if="v$.user.profile.firstName.required.$message">{{
    v$.user.profile.firstName.required.$message
}}</span>
                                </div>



                              </b-form-group>
                              <b-form-group class="mb-3" id="email-group" label="Last Name" label-for="lastName">
                                <b-form-input id="lastName" v-model="user.profile.lastName" type="text"
                                  placeholder="Enter last name" :class="{
  'is-invalid': submitted && v$.user.profile.lastName.$error,
}"></b-form-input>
                                <div v-if="submitted && v$.user.profile.lastName.$error" class="invalid-feedback">
                                  <span v-if="v$.user.profile.lastName.required.$message">{{
    v$.user.profile.lastName.required.$message
}}</span>
                                </div>



                              </b-form-group>
                              <b-form-group class="mb-3" id="email-group" label="Cellphone Number"
                                label-for="cellphoneNumber">


                                <b-form-input id="cellphoneNumber" v-model="user.cellphoneNumber" type="number"
                                  placeholder="Enter Cellphone number" ></b-form-input>
    <p
                  v-if="user.cellphoneNumber"
                  class="badge position-absolute"
                  :class="{ 'bg-success': user.cellphoneNumber.length !== 11,
                            'bg-danger': user.cellphoneNumber.length === 11 }"
                >
                
                  {{ user.cellphoneNumber.length }} / 11
                </p>
                                <div v-if="submitted && v$.user.cellphoneNumber.$error" class="invalid-feedback">
                                  <span v-if="v$.user.cellphoneNumber.required.$message">{{
    v$.user.cellphoneNumber.required.$message
}}</span>
                                </div>
                              </b-form-group>

                              <b-form-group class="mb-3" id="fullname-group" label="Email" label-for="email">
                                <b-form-input id="email" v-model="user.email" type="email" placeholder="Enter email"
                                  :class="{
  'is-invalid': submitted && v$.user.email.$error,
}"></b-form-input>
                                <div v-for="(item, index) in v$.user.email.$errors" :key="index"
                                  class="invalid-feedback">
                                  <span v-if="item.$message">{{ item.$message }}</span>
                                </div>
                              </b-form-group>

                              <b-form-group class="mb-3" id="password-group" label="Password" label-for="password">
                                <b-form-input id="password" v-model="user.password" type="password"
                                  placeholder="Enter password" :class="{
  'is-invalid': submitted && v$.user.password.$error,
}"></b-form-input>
                                <div v-if="submitted && v$.user.password.$error" class="invalid-feedback">
                                  <span v-if="v$.user.password.required.$message">{{
    v$.user.password.required.$message
}}</span>
                                </div>
                              </b-form-group>
                              <b-form-group class="mb-3" id="password-group" label="Confirm Password"
                                label-for="confirmPassword">
                                <b-form-input id="confirmPassword" v-model="user.confirmPassword" type="password"
                                  placeholder="Confirm password" :class="{
  'is-invalid': submitted && v$.user.confirmPassword.$error,
}"></b-form-input>
                                <div v-if="submitted && v$.user.confirmPassword.$error" class="invalid-feedback">
                                  <span v-if="v$.user.confirmPassword.required.$message">{{
    v$.user.confirmPassword.required.$message
}}</span>
                                </div>
                              </b-form-group>

                              <div class="mt-4 d-grid">
                                <b-button type="submit" variant="primary" class="btn-block">Register</b-button>
                              </div>

                              <div v-for="(error) in errorMessage">

                                <!-- <div v-for="errorDetail in error"> -->
                                {{ error }}

                                <!-- </div> -->




                              </div>

                              <!-- <div class="mt-4 text-center">
                              <h5 class="font-size-14 mb-3">Sign up using</h5>

                              <ul class="list-inline">
                                <li class="list-inline-item">
                                  <a href="javascript: void(0);" class="
                        social-list-item
                        bg-primary
                        text-white
                        border-primary
                      ">
                                    <i class="mdi mdi-facebook"></i>
                                  </a>
                                </li>
                                <li class="list-inline-item">
                                  <a href="javascript: void(0);"
                                    class="social-list-item bg-info text-white border-info">
                                    <i class="mdi mdi-twitter"></i>
                                  </a>
                                </li>
                                <li class="list-inline-item">
                                  <a href="javascript: void(0);" class="
                        social-list-item
                        bg-danger
                        text-white
                        border-danger
                      ">
                                    <i class="mdi mdi-google"></i>
                                  </a>
                                </li>
                              </ul>
                            </div> -->

                              <div class="mt-4 text-center">
                                <p class="mb-0">
                                  By registering you agree to the Platform's
                                  <a href="javascript: void(0);" class="text-primary">Terms of Use</a>
                                </p>
                              </div>
                            </b-form>
                            <div class="mt-5 text-center">
                              <p>
                                Already have an account ?
                                <router-link to="/login" class="fw-medium text-primary">Login</router-link>
                              </p>
                              <p>
                                © {{ new Date().getFullYear() }} D.O.T
                              </p>
                            </div>
                          </SimpleBar>
                        </div>
                        <!-- end card-body -->
                      </div>
                      <!-- end card -->


                      <!-- end row -->
                    </div>
                    <!-- end col -->
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </div>
  <!-- end container-fluid -->

</template>
